<template>
  <div class="content">
    <iframe
      src="https://docs.google.com/document/d/1Qk8RZQS7reHqpEOwNUluQODGg6DtDDmgzR5_AhTUWQQ/pub?embedded=true"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      class="iframe"
    >
      Loading…
    </iframe>

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useIsMobile from "@/composables/useIsMobile";
import NavMobile from "@/components/NavMobile.vue";

export default {
  components: { NavMobile },

  setup() {
    return {
      ...useIsMobile(),
    };
  },
};
</script>

<style scoped>
.content {
  border-radius: var(--button-border-radius);
  overflow: hidden;
  width: 100%;
  margin: 60px 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.iframe {
  width: 100%;
  height: 600px;
}
@media (max-width: 1200px) {
  .content {
    width: 100%;
    padding: 10px 0 70px;
    display: flex;
    align-items: center;
  }
  .iframe {
    width: 100%;
    height: 550px;
  }
}
</style>
